video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

h1 {
  text-align: center;
}

.hero {
  display: flex;
  flex-direction: row;
  height: 100vh;
  margin-top: -90px;
  width: 100%;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  background-size: cover;
}

.hero-container {
  height: 100vh;
  margin-top: -90px;
  width: 100%;
  display: flex;
  background-attachment: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  object-fit: contain;
}

.hero-container > h1 {
  color: #fff;
  font-size: 70px;
  width: 100%;
  text-shadow: 0 6px 20px rgb(1, 1, 2);
}

.hero-container > p {
  margin-top: 8px;
  color: #9fedd7;
  font-size: 32px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  text-shadow: 0 6px 20px rgb(1, 1, 2);
}

.hero-btns {
  margin-top: 50px;
}

.hero-btns > p {
  margin-top: 20px;
  color: #fff;
  font-size: 32px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  text-shadow: 0 6px 20px rgb(1, 1, 2);
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 1200px) {
  .hero-container > h1 {
    font-size: 60px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 900px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
