.cards {
  padding: 4rem;
  background-color: #e3eaed;
}

h1 {
  font-size: 3.5rem;
  padding-top: 1rem;
}

.cards,
p {
  color: black;
}

/* controls the overall container */
.cards__container {
  display: flex;
  flex-flow: column;
  /* align-items: center; */
  /* max-width: 1200px; */
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

/* wraps all 5 cards */
.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

/* wraps each card */
.cards__items {
  padding-bottom: 5rem;
}

/* each card */
.cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
  background-color: #fff;
  width: 50%;
  /* this controls height of image */
  height: 20rem;
  /* border-style: outset; */
}

/* outside of card? */
.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  /* box-shadow: 0 6px 30px rgba(0, 0, 1, 0.927); */
  /* -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017)); */
  filter: drop-shadow(0 6px 20px rgba(56, 126, 255, 0.276));
  /* border-radius: 10px; */
  overflow: hidden;
  text-decoration: none;
  padding-bottom: 0.5rem;
}

/* .cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 60%;
  overflow: hidden;
} */

.fade-img {
  animation-name: fade-img;
  animation-duration: 1s;
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #1f98f4;
  box-sizing: border-box;
}

/* the image that is in the card */
.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  /* height: 10rem; */
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  transform: scale(1.1);
}

.cards__item__info {
  padding: 20px 30px 30px;
}

/* projects descriptions */
.cards__item__text {
  color: #252e48;
  font-size: 16px;
  line-height: 22px;
}
/* 
@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
} */

/* @media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
} */

/* about */

h2 {
  text-align: center;
  font-size: 70px;
}

h3 {
  text-align: left;
  font-size: 40px;
  padding-top: 10rem;
}

/* about -------------------------------- */

.about {
  padding: 5rem;
  background-color: #e3eaed;
}

.about,
p {
  font-size: 50px;
}

ul {
  font-size: 40px;
  color: #1f98f4;
}

/* .pink {
  color: #fff;
  font-size: 20px;
  padding-right: 3rem;
}

.email {
  font-size: 1.5rem;
}

.photo {
  width: 100%;
  border-radius: 1rem;
  box-shadow: 0 6px 20px rgba(2, 5, 9, 0.961);
  overflow: hidden;
  max-height: 600px;
  max-width: 400px;
}

.td1 {
  width: 33%;
  padding: 1rem;
}

.td2 {
  font-size: 20px;
}

.about h2 {
  padding: 1rem;
} */
