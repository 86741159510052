.navbar {
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar.active {
  background: linear-gradient(90deg, #40c4ff, #1f98f4);
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2.5rem;
  display: flex;
  align-items: center;
}

.navbar-logo:hover {
  text-shadow: 0 6px 20px rgb(1, 1, 2);
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  list-style: none;
  text-align: center;
  width: 100vw;
  justify-content: end;
}

.nav-item {
  height: -80px;
}

/* the x that closes the active navbar */
.x-active-navbar {
  text-align: right;
  padding: 2rem;
  font-size: 2.3rem;
  color: #5387ce;
  cursor: pointer;
}

.x-active-navbar:hover {
  text-shadow: 0 6px 20px rgb(1, 1, 2);
}

.nav-links {
  color: #e3eaed;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 1rem;
  height: 100%;
  text-shadow: 0 6px 10px rgb(1, 1, 2);
  font-size: 2rem;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

/* hamburger menu */
.fa-bars {
  color: #fff;
}

.fa-bars:hover {
  text-shadow: 0 6px 20px rgb(1, 1, 2);
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 1000px) {
  .NavbarItems {
    position: relative;
    background-color: green;
  }

  /* side nav menu */
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 120vh;
    position: absolute;
    top: 0px;
    bottom: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.3s ease;
  }

  .nav-menu.active {
    background-color: #40c4ff;
    left: 0;
    opacity: 1;
    transition: all 0.3s ease;
    z-index: 1;
    top: 0px;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 2.5rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2.3rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}
